import { mapActions } from 'vuex'

export default {
  name: 'TFilterItem',
  props: {
    item: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {

    filterBy () {
      return this.item.filterBy.text
    },

    itemQuery () {

      if (this.item.filterBy.key === 'period' || this.item.filterBy.key === 'activeSince') {
        const localItem = `${this.item.fieldOne} - ${this.item.fieldTwo}`
        return localItem
      }

      if (this.item.fieldOne.text) {
        return this.item.fieldOne.text
      }

      return this.item.fieldOne
    }
  },
  methods: {
    ...mapActions('filter', [ 'removeItem' ]),
    removeItemUpdateList (item) {
      this.removeItem(item)
      this.$emit('filterUpdated', item)
    }
  }
}
