var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "item" }, [
    _c("div", { staticClass: "tui tuim ui grid" }, [
      _c("div", { staticClass: "left floated fifteen wide column" }, [
        _c("span", [
          _vm._v(
            _vm._s(_vm.filterBy) + " " + _vm._s(_vm.item.equalTo.text) + " "
          ),
          _c("strong", { domProps: { textContent: _vm._s(_vm.itemQuery) } })
        ])
      ]),
      _c(
        "span",
        {
          staticClass: "remove-item",
          on: {
            click: function($event) {
              return _vm.removeItemUpdateList(_vm.item)
            }
          }
        },
        [
          _c("t-icon", {
            staticClass: "tui tuim icon",
            attrs: { icon: "times" }
          })
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }